import Flickity from 'flickity'

var body = $('body'),
  allPhones = 'only screen and (max-width : 768px)',
  tablet = 'only screen and (min-width: 769px) and (max-width: 1024px)',
  tabletDown = 'only screen and (max-width: 1024px)',
  desktop = 'only screen and (min-width: 1025px)',
  loadingSpinner = '<div class="cssload-container"><div class="cssload-speeding-wheel"></div></div>';

var enableFitVids = function () {
  "use strict";
  jQuery(".video-embed, .wysiwyg-content").fitVids();
};

var scroller = function () {
  "use strict";
  var $nano = $(".nano"),
    options = {
      iOSNativeScrolling: true,
      alwaysVisible: true
    };
  $nano.nanoScroller(options);
  $nano.imagesLoaded(function () {
    $nano.nanoScroller(options);
  });
};

var featuredProducts = function () {
  "use strict";
  var $container = $('.featured-products__preview__container'),
    $item = $('.featured-products__item'),
    $previewImage = $('.featured-products__preview__image'),
    $previewTitle = $('.featured-products__preview__text');

  $item.hover(function () {
    var img = $(this).data('img-src'),
      title = $(this).find('.featured-products__item__title').html();

    $container.show();
    $('.featured-products__item').removeClass('active');
    $(this).addClass('active');
    $previewImage.attr('src', img);
    $previewTitle.html(title);
  });
};

var listingToggle = function () {
  "use strict";
  if ($('html.localstorage').length) {
    $(".listing-toggle a").on('click', function (e) {
      e.preventDefault();
      $('.listing-toggle a').removeClass('active');
      $(this).addClass('active');
      $('body').removeClass('thumbnail-view').removeClass('listing-view').addClass($(this).attr('data-view'));
      localStorage.setItem('listingMode', $(this).attr('data-view'));
      setTimeout(function () {
        $(document).foundation('equalizer', 'reflow');
      }, 450);
      $(document).foundation('equalizer', 'reflow');
    });
    if (!localStorage.getItem('listingMode')) {  // list mode not set
      if ($('.listing-layout').length) {
        localStorage.setItem('listingMode', 'thumbnail-view');
      }
    } else {  // already set, so use it
      $('.listing-toggle a').removeClass('active');
      $('.listing-toggle a[data-view="' + localStorage.getItem('listingMode') + '"]').addClass('active');
      if ($('.listing-toggle').length) {
        $('body').removeClass('thumbnail-view').removeClass('listing-view').addClass(localStorage.getItem('listingMode'));
      }
      $(document).foundation('equalizer', 'reflow');
    }
  }
};

var handleBrokenImages = function () {
  "use strict";
  $("img").not('.product-card__image').on('error', function () {
    $(this).attr('src', assetsUrl + 'img/no-image.jpg').addClass('no-image');
  });
};


var newsLetterEffect = function () {
  "use strict";
  var newsletterClass = '.newsletter-signup';
  $(newsletterClass + ' input[type=email]').on('focus', function () {
    $(this).parents(newsletterClass).addClass('focusing');
  }).on('blur', function () {
    $(this).parents(newsletterClass).removeClass('focusing');
  });
};

var styledSelect = function () {
  "use strict";
  $("select.styled").each(function () {
    var $this = $(this),
      selectedOption = $this.find(":selected").text();

    if ($this.parent('.select-wrapper').length === 0) {
      $this.wrap("<span class='select-wrapper'></span>");
      $this.after("<span class='holder'>" + selectedOption + "</span>");
    } else {
      $this.next(".holder").text(selectedOption);
    }

  });

  $(document).on('change', 'select.styled', function () {
    var $this = $(this),
      selectedOption = $this.find(":selected").text();

    $this.next(".holder").text(selectedOption);
  });

};

// var arrangeOnMobile = function() {
//   "use strict";
//
//   // Use this function to rearrange elements in the DOM
//   // for when Foundation isn't up to the task without breaking the layout
//
//   var $singleRecipePicture = $('.single-recipe__picture'),
//       $singleRecipePictureTarget = $('.single-recipe__presentation');
//
//   if(Modernizr.mq(tabletDown)) {
//     if($singleRecipePicture.length && $singleRecipePictureTarget.length) {
//       $singleRecipePicture.detach().prependTo($singleRecipePictureTarget);
//     }
//   }
//   /*
//   if(Modernizr.mq(allPhones)) {
//   }
//   */
// };

$(document).ready(function () {
  "use strict";
  $('[disabled], [class$="--disabled"]').on('click', function (e) {
    return e.preventDefault();
  });

  $(document).foundation({
    reveal: {
      opened: function () {
        $('body').addClass('modal-open');
      },
      close: function () {
        $('body').removeClass('modal-open');
      }
    },
    equalizer: {
      equalize_on_stack: true
    },
    tab: {
      callback: function (tab) {
        $(document).foundation('equalizer', 'reflow');
      }
    }
  });

  $('input, textarea').placeholder();
  enableFitVids();
  scroller();
  if (Modernizr.mq(desktop)) {
    featuredProducts();
  }
  listingToggle();
  handleBrokenImages();
  newsLetterEffect();
  styledSelect();
  //arrangeOnMobile();
});

$(window).on('load', function () {
  "use strict";
  body.removeClass('loading');
});


// Scroll to thank you div
var thankYou = function () {
  "use strict";
  if ($(".thank-you-message").length) {
    $('html, body').animate({
      scrollTop: $('.thank-you-message').offset().top
    }, 'slow');
  }
};

var openLoginCard = function () {
  "use strict";
  var cta = $('.login__cta, .login__card');
  if ($('.disabled').length) {
    return false;
  } else {
    cta.hover(function () {
      body.toggleClass('login__card--show');
    });
  }
};

var notification = function () {
  "use strict";
  if ($('.notification-wrapper.normal-notification').length) {
    var notification = $('.notification');

    notification.addClass('enter');

    setTimeout(function () {
      notification.removeClass('enter');
    }, 5000);

  }
};

var showTooltips = function () {
  "use strict";
  var button = $('.tooltip-container');

  button.hover(function () {
    var $this = $(this);
    $this.toggleClass('active');
  });
};

// Flickity slider for related products on a single recipe pages
var relatedProductsSlider = function () {
  "use strict";
  var elem = document.querySelector('.main-carousel');
  if (elem) {
    var flkty = new Flickity(elem, {
      // options
      cellAlign: 'left',
      contain: true,
      pageDots: false,
    });
  }
};



var revealDropdowns = function () {
  'use strict';
  var dropdowns = document.querySelectorAll('.hide-dropdown');
  for (var index = 0; index < dropdowns.length; index++) {
    var element = dropdowns[index];
    $(element).removeClass('hide');
  }
};

$(document).ready(function () {
  "use strict";
  thankYou();
  openLoginCard();
  notification();
  showTooltips();
  relatedProductsSlider();
  revealDropdowns();
});

$(document).on('notify', function () {
  "use strict";
  notification();
});
